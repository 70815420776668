


















import { Component, Vue } from 'vue-property-decorator';

import ProblemPage from '@/assets/imgs/maintenance/ProblemPage.vue';

import WrapperInstability from '@/components/WrapperInstability/WrapperInstability.vue';

import instability from '@/mixins/Instability';

@Component({
  mixins: [instability],
  components: {
    WrapperInstability,
    ProblemPage
  }
})
export default class Instability extends Vue {
  redirectToDashboard() {
    this.$router.push({
      name: 'Dashboard'
    });
  }
}
